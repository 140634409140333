.contactContainerVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactContainerHorizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.center {
    text-align: center;
}

.contactPageButton {
    padding: 1rem;
    border-radius: 0.4rem;
    font-weight: 500;
    background-color: var(--main-accent);
    text-align: center;
    margin-bottom: 1.2rem;
    margin-right: 0.8rem;
}

.contactPageButton:hover {
    background-color: var(--secondary-accent);
}

.contactPageIcon {
    margin-right: 0.8rem;
}

.longButton {
    width: 100%;
}