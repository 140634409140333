html, body {
    height: 100%;
    margin: 0;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.app {
    display: flex;
    flex-direction: column;
    background-color: var(--main-white);
    font-family: poppins;
    min-height: 100vh;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.introContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    background-color: var(--main-background);
    padding-bottom: 1.48rem;
    box-shadow: 0px 2px 7px 2px var(main-shadow);
    overflow: hidden;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.mobileIntroContainer {
    height: 78vh;
}

.introBackgroundText {
    font-size: 18vw;
    line-height: 90%;
    color: var(--secondary-text);
    font-weight: 900;
    text-align: center;
    z-index: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    animation: fadeIn 3s ease-in-out;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.nameContainer {
    position: absolute;
    min-width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-bottom: 0.7vw;
}

.introName {
    font-size: calc(2rem + 1.2vw);
    color: var(--white-text);
    animation: fadeIn 1s ease-in-out;
}

.introTitle {
    font-size: calc(2rem + 1.2vw);
    color: var(--main-accent);
    animation: fadeIn 1s ease-in-out;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.introTitleMobile {
    padding-bottom: 4rem;
}

.recentProjectsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    animation: fadeIn 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.projectsHeader {
    font-size: calc(1.4rem + 0.2vw);
    font-weight: 400;
    color: var(--main-dark);
    margin: 4rem;
    margin-bottom: 0rem;
}

.horizontalLine {
    width: 80%;
    height: 0.1rem;
    background-color: var(--main-dark);
    margin: 1.48rem;
}

.projectsGrid {
    display: grid;
    width: 80%;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    justify-items: center;
    margin: 1.48rem;
    margin-bottom: 0rem;
}

.seeMoreContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    color: var(--main-dark);
}

.seeMoreContainer:hover {
    color: var(--main-accent);
}

.seeMoreText {
    font-size: calc(1rem + 0.2vw);
    margin-right: 0.5rem;
}

.noShow {
    opacity: 0;
    animation: none;
}

.particleAnimationBlur {
    filter: blur(40px);
}

.downArrowCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 78vh;
    width: 2rem;
    height: 2rem;
    background-color: var(--main-accent);
    color: var(--white-text);
    border-radius: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}