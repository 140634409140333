.pageContent {
    flex: 1;
    padding: 4rem;
    padding-left: 6vw;
    padding-right: 6vw;
    max-width: 100vw;
    animation: fadeIn 0.5s ease-in;
}

.projectsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18.5rem, 1fr));
}

.paddingContainer {
    padding-left: 8vw;
    padding-right: 8vw;
}

.innerContent {
    width: 70rem;
    max-width: 90vw;
    padding-left: 2rem;
    padding-right: 2rem;
}

.pageHeader {
    font-size: calc(1.5rem + 0.2vw);
    font-weight: 600;
    color: var(--main-dark);
}

.pageSubheader {
    font-size: calc(1rem + 0.2vw);
    font-weight: 500;
    margin-bottom: 1.8rem;
    color: var(--main-dark);
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-text);
}

.projectDescriptionContainer {
    display: flex;
    flex-direction: row;
    margin-top: 1.8rem;
    width: 100%;
}

.column {
    flex-direction: column;
}

.projectImageContainer {
    height: 14rem;
    width: 30rem;
    max-height: 10rem;
    max-width: 17rem;
    margin-right: 1.8rem;
    margin-bottom: 1.8rem;
    border-radius: 18px;
    border: 1px solid var(--main-dark);
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.projectImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.projectFullDescription {
    height: 100%;
    width: 100%;
    margin-bottom: 1.8rem;
    font-size: 1rem;
    font-weight: 400;
    color: var(--main-dark);
}

.skillsHeader {
    font-size: calc(1rem + 0.2vw);
    font-weight: 600;
    color: var(--main-dark);
}

.skills {
    font-size: 1rem;
    margin-top: 0.4rem;
    color: var(--main-dark);
}

.linkReplacement {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.8rem;
    color: var(--main-dark);
}

.buttonContainer {
    margin-top: 1.8rem;
}

.linkButton {
    width: 100%;
    max-width: 90vw;
    margin-bottom: 0.8rem;
    padding: 0.5rem;
    border-radius: 0.4rem;
    font-weight: 500;
    background-color: var(--main-accent);
    text-align: center;
    color: var(--main-white);
}

.linkButton:hover {
    background-color: var(--secondary-accent);
}

.backbutton {
    width: 100%;
    max-width: 90vw;
    margin-bottom: 0.8rem;
    padding: 0.5rem;
    border-radius: 0.4rem;
    font-weight: 500;
    background-color: var(--secondary-background);
    text-align: center;
    color: var(--main-white);
}

.backbutton:hover {
    background-color: var(--secondary-background);
}

.demoVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--main-dark);
    border-radius: 0.4rem;
    overflow: hidden;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}