.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    background-color: var(--secondary-background);
    color: var(--main-dark);
    font-size: calc(1rem + 0.2vw);
    box-shadow: inset 0px 2px 8px 2px var(--secondary-shadow);
    transition: background-color 0.5s ease, color 0.5s ease;
}

.currentlyText {
    margin: 1.2rem;
}

.footerLinks {
    font-size: calc(1rem + 0.7vw);
}

.contactIcon {
    margin: 1.2rem;
}

.footerSmall {
    justify-content: center;
    font-size: 1.7rem;
}