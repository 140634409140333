.sectionContainer {
    width: 100%;
    padding: 2rem;
    box-shadow: 0px 2px 5px 2px var(--main-shadow);
}

.sectionInnerContainer {
    display: flex;
    flex-direction: row;
}

.sectionContentContainer {
    width: 70rem;
    max-width: 90vw;
    padding-left: 2rem;
    padding-right: 2rem;
    color: var(--main-dark);
}

.introductionTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 2rem;
}

.introductionCarouselContainer {
    height: calc(8rem + 8vw);
    width: 100%;
    border-radius: 7px;
    overflow: hidden;
}

.introductionCaptionCarouselContainer div {
    display: flex;
    align-items: center;
}

.introductionCaptionCarouselContainer p {
    align-self: center;
    width: 100%;
}

.introductionCaptionCarouselContainer {
    margin-top: 0.8rem;
    height: calc(1rem + 1vw);
    width: 33%;
    min-width: 18rem;
    border-radius: 7px;
    overflow: hidden;
}

.introductionImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
    /* box-shadow: 0 0 10px 0 var(--main-shadow); */
}

.resumeHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.resumeHeader {
    font-size: calc(1.5rem + 0.2vw);
    font-weight: 400;
    margin-bottom: 1.2rem;
}

.resumeButton {
    padding: 0.5rem;
    border-radius: 0.4rem;
    font-weight: 500;
    background-color: var(--main-accent);
    text-align: center;
    margin-bottom: 1.2rem;
}

.resumeButton:hover {
    background-color: var(--secondary-accent);
}

.fullWidthHorizontalLine {
    width: 100%;
    height: 0.05rem;
    background-color: var(--main-dark);
}

.resumeSubheader {
    font-size: calc(1rem + 0.2vw);
    font-weight: 600;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}

.resumeSubsubheader {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
}

.resumeDescription {
    margin-bottom: 0.8rem;
}

.indent {
    margin-left: 4rem;
}

.list {
    margin-left: 1rem;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.extraTopPadding {
    margin-top: 4rem;
}

.imageSelectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.8rem;
}

.imageToSelectContainer {
    position: relative;
    width: 15%;
    border-radius: 12px;
    border: 1px solid var(--main-dark);
    overflow: hidden;
}

.imageSelection {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.selectedImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
}

.hidden {
    display: none;
}

.extraBottomPadding {
    padding-bottom: 1.8rem;
}

.introductionCarousel {
    height: 100%;
}

.pageContentSmall {
    padding-top: 1rem;
}