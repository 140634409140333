.nav {
    position: sticky;
    width: 100vw;
    top: 0;
    background-color: var(--main-background);
    z-index: 4;
}

.relative {
    position: relative;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--main-white);
    padding: 1.48rem;
    background-color: var(--main-background);
    z-index: 4;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.navLogo {
    font-size: calc(0.8rem + 0.9vw);
    font-weight: 600;
    color: var(--white-text);
    transition: color 0.3s;
}

.navLogo:hover {
    color: var(--main-accent);
}

.nav-links {
    display: flex;
    width: calc(18rem + 5.96vw);
    justify-content: space-between;
    font-size: calc(1rem + 0.2vw);
}

.nav-link {
    color: var(--white-text);
    text-decoration: none;
    transition: color 0.3s;
}

.selected {
    color: var(--white-text);
}

.squiggle {
    align-self: center;
    width: 5.3rem;
    padding-top: 0.2rem;
    transition: opacity 0.3s ease;
}

.link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.opacity {
    opacity: 1;
}

.dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100vw;
    height: fit-content;
    background-color: var(--main-background);
    overflow: hidden;
    opacity: 0;
    transform: translateY(-5%);
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    pointer-events: none;
    z-index: 3;
}

.open {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}

.navItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 0;
    background-color: var(--secondary-text);
    font-size: calc(1rem + 0.2vw);
    color: var(--white-text);
    text-decoration: none;
    padding: 1.8rem;
    border-bottom: 1px solid var(--secondary-background);
}

.navItem:hover {
    background-color: var(--secondary-background);
}

.nav-link:hover {
    color: var(--main-accent);
}

.mobileMenuIcon {
    color: var(--white-text);
}

@keyframes expand {
    from {
        max-height: 0;
    }

    to {
        max-height: 9.8rem;
    }
}