.card {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 18rem;
    min-width: 18rem;
    border-radius: 8px;
    background-color: var(--secondary-background);
    margin-bottom: 3rem;
    padding: 1.2rem;
    box-shadow: 0px 5px 4px -3px var(--main-shadow);
    transition: background-color 0.5s ease;
}

.card:hover {
    background-color: var(--secondary-background-hover);
}

.cardImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cardImageContainer {
    height: 10rem;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--main-dark);
    overflow: hidden;
}

.projectHeader {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--dark-text);
    margin-top: 1.2rem;
    margin-bottom: 0.2rem;
}

.projectDescription {
    color: var(--dark-text);
}