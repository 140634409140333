.loading-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--main-background);
    z-index: 1000;
}

.spinner {
    color: var(--main-accent);
}

.loadingText {
    padding: 20px;
    border-radius: 5px;
    font-size: 20px;
    color: var(--white-text);
    font-size: 1.2rem;
}